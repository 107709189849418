import { useState, useEffect, useRef } from 'react';

/**
 * Hook to check if a value has changed after a delay.
 * @param prop - Value to check for change
 * @param delay - Delay in milliseconds
 * @returns Boolean - true if the value has changed, false otherwise
 */
const useIsValueChanged = (prop: unknown, delay = 1000): boolean => {
    const [hasChanged, setHasChanged] = useState(false);
    const prevProp = useRef(prop);

    useEffect(() => {
        if (prevProp.current !== prop) {
            setHasChanged(true);

            const timeoutId = setTimeout(() => {
                setHasChanged(false);
            }, delay);

            prevProp.current = prop;

            return () => clearTimeout(timeoutId);
        }
    }, [prop, prevProp, delay]);

    return hasChanged;
};

export { useIsValueChanged };
